// src/main.js
import { createI18n } from 'vue-i18n';
import en from './modules/en'
// import zh from './modules/zh'
import tw from './modules/tw'
import ru from './modules/ru'

const message = {
    'en-us': en,
    // 'zh-cn': zh,
    'zh-cn': tw,
    'ru-ru': ru
}
 
const i18n = createI18n({
  legacy: false,  // 设置为 false，启用 composition API 模式
  messages: message,
  locale: localStorage.getItem('locale') ? localStorage.getItem('locale') : 'en-us' // 设置默认语言
})

export default i18n