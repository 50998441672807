export default {
    "收益率": "Доходность",
    "结束时间": "Время окончания",
    "短期融资": "Краткосрочное финансирование",
    "记录": "Запись",
    "转出本金": "Вывод основного капитала",
    "转出收益": "Вывод дохода",
    "钱包余额": "Баланс кошелька",
    "天": "Дни",
    "请输入用户名": "Введите имя пользователя",
    "请输入密码": "Введите пароль",
    "提交": "Отправить",
    "立即开户": "Открыть счет сейчас",
    "联系客服": "Связаться с клиентской поддержкой",
    "用户名": "Имя пользователя",
    "密码": "Пароль",
    "确认密码": "Подтвердите пароль",
    "请确认密码": "Пожалуйста, подтвердите пароль",
    "邀请码": "Код приглашения",
    "请输入邀请码": "Введите код приглашения",
    "真是姓名(可选)": "Настоящее имя (необязательно)",
    "身份证号码(可选)": "Номер удостоверения личности (необязательно)",
    "已经有一个帐户": "Уже есть аккаунт",
    "版权所有©2024TS股份有限公司保留所有权利": "Авторские права ©2024 TS Co., Ltd. Все права защищены",
    "此网站支持IPv6访问": "Этот сайт поддерживает доступ по IPv6",
    "在线用户": "Пользователи онлайн",
    "快速入口": "Быстрый вход",
    "支持全球USDT输入": "Поддержка глобального ввода USDT",
    "关于我们": "О нас",
    "帮助中心": "Центр помощи",
    "市场": "Рынок",
    "产品趋势": "Тренды продуктов",
    "订单历史": "История заказов",
    "我的账户": "Мой аккаунт",
    "持仓": "Позиции",
    "今日开业": "Открытие сегодня",
    "昨天收盘": "Закрытие вчера",
    "最高": "Максимум",
    "最低": "Минимум",
    "K线": "K-линия",
    "趋势": "Тренд",
    "挂单": "Отложенные заказы",
    "数量": "Количество",
    "买涨价": "Цена покупки",
    "买跌价": "Цена продажи",
    "时间": "Время",
    "去向": "Направление",
    "买涨": "Покупка",
    "买跌": "Продажа",
    "持仓详情": "Детали позиций",
    "结算时间": "Время расчета",
    "购买时间": "Время покупки",
    "盈利": "Прибыль",
    "没有更多数据": "Больше данных нет",
    "购买确认": "Подтверждение покупки",
    "名称": "Наименование",
    "方向": "Направление",
    "当前价格": "Текущая цена",
    "账户余额": "Баланс счета",
    "秒": "Секунды",
    "最小购买金额等于或大于": "Минимальная сумма покупки равна или больше",
    "收益": "Доход",
    "取消": "Отмена",
    "确定": "Подтвердить",
    "持仓中": "В позиции",
    "历史持仓": "Исторические позиции",
    "利润/损失/时间": "Прибыль/Убыток/Время",
    "产品/金额": "Продукт/Сумма",
    "加载中": "Загрузка",
    "充值": "Пополнение",
    "提款": "Вывод",
    "财务详细信息": "Финансовые детали",
    "实名认证": "Аутентификация по имени",
    "提款历史记录": "История вывода средств",
    "订单历史记录": "История заказов",
    "我的团队": "Моя команда",
    "邀请": "Приглашение",
    "用户等级": "Уровень пользователя",
    "杠杆作用": "Кредитное плечо",
    "修改密码": "Изменить пароль",
    "切换语言": "Сменить язык",
    "退出登录": "Выйти",
    "存入市场": "Внести на рынок",
    "充值数量": "Сумма пополнения",
    "登录密码": "Пароль для входа",
    "提款密码": "Пароль для вывода средств",
    "首次设置时，请输入4位提款密码": "Введите 4-значный пароль для вывода средств при первой настройке",
    "请重新输入4位数字密码": "Пожалуйста, введите 4-значный числовой пароль повторно",
    "新密码": "Новый пароль",
    "请仔细检查您的信息": "Пожалуйста, внимательно проверьте информацию",
    "银行名称": "Название банка",
    "银行代码": "Код банка",
    "银行账户": "Банковский счет",
    "持卡人": "Держатель карты",
    "银行分行": "Филиал банка",
    "提款数量": "Сумма вывода",
    "请输入提款数量": "Пожалуйста, введите сумму вывода",
    "友情提示": "Дружеское напоминание",
    "印花税": "Гербовый сбор",
    "如果你有任何问题，请": "Если у вас есть вопросы, пожалуйста",
    "时间": "Время",
    "过滤": "Фильтр",
    "开始": "Начало",
    "结束": "Конец",
    "请选择时间": "Пожалуйста, выберите время",
    "交易类型": "Тип транзакции",
    "投资结算": "Инвестиционные расчеты",
    "在线充值": "Онлайн пополнение",
    "提款退款": "Возврат вывода",
    "投资支出": "Инвестиционные расходы",
    "提款申请": "Запрос на вывод",
    "提款成功": "Вывод успешен",
    "重置": "Сброс",
    "实名制验证": "Проверка реального имени",
    "匿名用户": "Анонимный пользователь",
    "真实姓名": "Настоящее имя",
    "请输入真实姓名": "Пожалуйста, введите настоящее имя",
    "身份证号码": "Номер удостоверения личности",
    "请输入身份证号码": "Пожалуйста, введите номер удостоверения личности",
    "邀请二维码": "Пригласительный QR-код",
    "复制邀请链接": "Скопировать ссылку приглашения",
    "复制成功": "Копирование успешно",
    "下属人数": "Количество подчиненных",
    "团队规模": "Размер команды",
    "团队余额": "Баланс команды",
    "团队帐户更改": "Изменение счета команды",
    "团队投资": "Инвестиции команды",
    "团队报告": "Отчет команды",
    "状态": "Статус",
    "未解决": "Не решено",
    "稳定的": "Стабильно",
    "概述": "Обзор",
    "总余额": "Общий баланс",
    "投资": "Инвестиции",
    "盈利能力": "Прибыльность",
    "存取款差异": "Разница между депозитом и снятием",
    "总损益": "Общая прибыль/убыток",
    "退出成功": "Выход успешен",
    "请先完成实名认证": "Пожалуйста, сначала пройдите проверку реального имени",
    "绑定银行卡": "Привязать банковскую карту",
    "请输入4位数字密码以验证您的身份": "Введите 4-значный цифровой пароль для проверки вашей личности",
    "请绑定银行卡": "Пожалуйста, привяжите банковскую карту",
    "收入": "Доход",
    "支出": "Расход",
    "价格": "Цена",
    "此功能尚未打开": "Эта функция еще не доступна",
    "固定": "Фиксированный",
    "操作": "Операция",
    "详情": "Подробности",
    "复制失败": "Ошибка копирования",
    "切换成功": "Переключение успешно",
    "网上借款": "Онлайн заимствование",
    "公告": "Объявление",
    "银行卡": "Банковская карта",
    "请选择银行": "Пожалуйста, выберите банк",
    "请填写充值金额": "Пожалуйста, укажите сумму пополнения",
    "提款地址": "Адрес для вывода",
    "请输入提款地址": "Пожалуйста, введите адрес для вывода",
    "您还未绑定提款地址，请先绑定提款地址!": "Вы еще не привязали адрес для вывода, сначала привяжите его!",
    "USDT地址": "Адрес USDT",
    "应转USDT金额": "Сумма перевода в USDT",
    "客服": "Служба поддержки",
    "请先设置提款密码": "Пожалуйста, сначала установите пароль для вывода",
    "余额": "Баланс",
    "格式不正确": "Неправильный формат",
    "取款支出": "Расходы на снятие",
    "取款退回": "Возврат снятия",
    "充值成功": "Пополнение успешно",
    "上次交易": "Последняя транзакция",
    "APP下载": "Скачать приложение",
    "佣金": "Комиссия",
    "账户余额R50000以下，1:1配比": "Баланс счета ниже R50000, соотношение 1:1",
    "账户余额R50001-R100000，1:2配比": "Баланс счета R50001-R100000, соотношение 1:2",
    "账户余额R100001-R200000，1:3配比": "Баланс счета R100001-R200000, соотношение 1:3",
    "账户余额R200001-R500000，1:4配比": "Баланс счета R200001-R500000, соотношение 1:4",
    "账户余额R500000以上，1:5配比": "Баланс счета выше R500000, соотношение 1:5",
    "比如你的账户有R100000，那么平台会按照1:2的比例给你账户充值R200000，那么你的投资本金即为R300000": "Например, если на вашем счете R100000, платформа пополнит ваш счет на R200000 в соотношении 1:2, и ваш инвестиционный капитал составит R300000",
    "完成45天的合约后，你在归还平台配比本金的同时，还需要支付10%的盈利佣ин": "После завершения 45-дневного контракта вы должны вернуть платформе соразмерный капитал и выплатить 10% комиссии от прибыли",
    "当账户余额亏损至本金的30%，那么系统将自动解除合约": "Если баланс счета снизится до 30% от капитала, система автоматически расторгнет контракт",
    "说明": "Описание",
    "点击参与": "Нажмите для участия",
    "参与成功": "Участие успешно",
    "已参与": "Уже участвует",
    "正面": "Лицевая сторона",
    "反面": "Обратная сторона",
    "请上传证件": "Пожалуйста, загрузите документы",
    "系统错误，请稍后尝试！": "Системная ошибка, пожалуйста, попробуйте позже!",
    "全民代理": "Всенародный агент",
    "区域总代理—需要拥有多年品牌推广经验，以及管理能力，需经平台审核资质并缴纳50万美金的保证金,可享受代理团队总收益的2%": "Региональный генеральный агент - требуется многолетний опыт продвижения бренда, а также управленческие навыки, квалификация, проверенная Платформой, и залог в размере 500 000 долларов США, что составляет 2% от общей прибыли команды агента",
    "一级代理人，享受下级收益的10%": "Агент первого уровня, получающий 10% от дохода нижнего уровня",
    "二级代理人，享受下级收益的5%": "Агент второго уровня, получающий 5% от дохода нижнего уровня",
    "三级代理人，享受下级收益的3%": "Агент третьего уровня, получающий 3% от дохода нижнего уровня",
    "投资币": "Инвестиционная валюта"
}